<script>
    import {metatags} from '@roxi/routify';
    import Carousel from "../components/carousel-zoom/Carousel.svelte";

    metatags.title = 'Sapin de Noël artificiel'

    const base_url = "https://multimedia.bbycastatic.ca/multimedia/products/500x500/130/13021";

    const imageList = {
        selected: '/images/sapins/DMC05373.jpg', //`${base_url}/13021743.jpg`,
        list: [
            '/images/sapins/DMC05373.jpg',
            '/images/sapins/DMC05387.jpg',
            '/images/sapins/DMC05415.jpg',
            '/images/sapins/DMC05427.jpg',
            '/images/sapins/DMC05450.jpg',
            '/images/sapins/DMC05460.jpg',
        ]
    };

    const treeList = [
        {
            img: '/images/sapins/DMC05373.jpg',
            title: 'Sapin',
            description: 'Advice me cousin an spring of needed. Tell use paid law ever yet new.',
        }
    ];
</script>

<style>.text-wrapper p{margin-bottom:.5rem}</style>

<!-- featured section -->
<div class="flex flex-col md:flex-no-wrap space-x-0 md:space-x-6 mb-5">
    <div class="rounded-xl flex flex-col md:flex-row md:shadow p-3 bg-white">
        <div class="w-full md:w-1/2">
            <Carousel images={imageList}/>
        </div>

        <div class="w-full md:w-1/2">
            <h2 class="text-green-700 font-bold text-madder-500 text-2xl mt-4">"Elite"</h2>
            <span class="text-green-700 font-bold hidden md:block">Qualité supérieure au meilleur prix</span>
            <div class="text-wrapper">
                <p>
                    Les sapins artificiels haut de gamme offrent de belles imitations.
                </p>
                <p class="italic text-coral-600 text-right pl-12">
                    Leur avantage premier est qu’ils ne perdent pas leurs aiguilles et qu’ils sont plus économiques car
                    ne s’achètent qu’une fois.
                </p>
                <p class="mt-5">
                    L’arbre mesure environ 210 cm de haut et 140 cm de diamètre au niveau le plus large.
                </p>
                <p class="italic text-coral-600 text-right pl-12">
                    Grâce à son système parapluie révolutionnaire, l'installation de ce sapin ne nécessite que quelques minutes.
                </p>

                <p class="mt-5">
                    Carcasse en métal, avec un socle en métal pliable pour une installation immédiate à l’endroit souhaité.
                </p>
                <p class="my-5 italic text-coral-600 text-right pl-12">
                    99% PE - plastique de qualité <br/>
                </p>
            </div>
            <div class="my-3">
                <span class="font-bold text-xl text-madder-600 font-sans">
                    Prix: 269.00 €
                </span>
            </div>
        </div>
    </div>

</div>
<!-- end popular posts -->
