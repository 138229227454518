<script></script>

<style global>:global(body){--bg-opacity:1;background-color:#fdfaf3;background-color:rgba(253,250,243,var(--bg-opacity));--bg-opacity:0.5}:global(.main-wrap){display:flex;flex-direction:column;height:100vh;width:100%}:global(.shop-nav){width:100%;z-index:10;top:0}:global(.shop-nav-wrap){width:100%;align-items:center;justify-content:space-between;--text-opacity:1;color:#2b6cb0;color:rgba(43,108,176,var(--text-opacity));--bg-opacity:1;background-color:#fbf7eb;background-color:rgba(251,247,235,var(--bg-opacity));--bg-opacity:0.5}:global(.shop-nav-top){flex-wrap:wrap;height:100%;vertical-align:middle}:global(.shop-nav-logo),:global(.shop-nav-top){display:flex;align-items:center}:global(.shop-nav-logo){flex-shrink:0;margin-right:1.5rem}</style>

<div class="max-w-screen-xl mx-auto">
    <!-- header -->
    <header class="flex items-center justify-center py-2">
        <a href="#" class="px-2 lg:px-0 font-bold capitalize text-5xl font-bold font-christmas tracking-wider">
            <span class="text-laurel-600">Sapins de</span>
            <span class="text-madder-600">Noël</span>
            <span class="text-green-700 normal-case italic text-base text-right font-mono tracking-normal block">
                produits en Ukraine
            </span>
        </a>
    </header>
    <!-- header ends here -->

    <main class="mt-5 p-3">
        <slot/>
    </main>

    <!-- footer -->
    <footer class="border-t mt-12 p-5 bg-rgreen-700 bg-opacity-75 text-white text-xs">
        <div class="flex justify-center">
            <a href="mailto:contact@raenbi.be" target="_blank">contact@raenbi.be</a>
        </div>
    </footer>
</div>
